import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import illustrationRo from '../../images/other-art/illustration-ro.png'
import "../layout.css"

import img1 from '../../images/other-art/illustration/1.jpg'
import img2 from '../../images/other-art/illustration/2.jpg'
import img3 from '../../images/other-art/illustration/3.jpg'
import img4 from '../../images/other-art/illustration/4.jpg'
import img5 from '../../images/other-art/illustration/5.jpg'
import img6 from '../../images/other-art/illustration/6.jpg'
import img7 from '../../images/other-art/illustration/7.jpg'
import img8 from '../../images/other-art/illustration/8.jpg'
import img9 from '../../images/other-art/illustration/9.jpg'
import img10 from '../../images/other-art/illustration/10.jpg'
import img11 from '../../images/other-art/illustration/11.jpg'
import img12 from '../../images/other-art/illustration/12.jpg'
import img13 from '../../images/other-art/illustration/13.jpg'

export default function Illustration() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13]
    return (
        <GalleryBackground title="Illustration" imgSrc={illustrationRo}>
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}